import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import useBusinessStats from '../../hooks/useGetDashboardInformation.ts';
import { useAuth0 } from '@auth0/auth0-react';
import useClientStats from '../../hooks/useClientStats.ts';
import { Link } from 'react-router-dom/cjs/react-router-dom.js';
import useAdminUser from '../../hooks/useAdminUser.ts';

const Dashboard: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
  const {user: adminUser} = useAdminUser()
  const [startDate, setStartDate] = useState<string>('2020/05/01');
  const [endDate, setEndDate] = useState<string>('2028/10/30');

  const adminAuth0Id = user?.sub; // Assuming `sub` is the unique identifier for the user

  const { data, refetch } = useBusinessStats({ adminAuth0Id: adminAuth0Id, startDate, endDate });
  const { data: clientsData } = useClientStats({ adminAuth0Id: adminAuth0Id })
  const allTimeCashbackStats = data?.allTimeCashbackStats
  const cashbackStats = data?.cashbackStats

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  useEffect(() => {
    console.log('Admin User', adminUser)
  }, [adminUser]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (authLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to view business stats.</div>;
  }


  // Dummy data for clients
  const clients = clientsData ?? []

  const filteredClients = clients.filter(client =>{
    const name = `${client.waProfileName}`
    return name.toLowerCase().includes(searchTerm.toLowerCase())
  });
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Dashboard - {adminUser?.adminInformation.business.friendlyName}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">${allTimeCashbackStats?.totalAmountPaid}</Typography>
            <Typography variant="subtitle1">Ventas Generadas</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">{allTimeCashbackStats?.approvedCount}</Typography>
            <Typography variant="subtitle1">Compras Realizadas</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">{allTimeCashbackStats?.firstTimeCashbackUsers}</Typography>
            <Typography variant="subtitle1">Clientes Nuevos</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container>
          <Grid xs={4}>
            <Typography variant="h6">Estadísticas por Fecha</Typography>
            <Typography variant='subtitle2' style={{color: '#1f1f1f'}}>{new Date(startDate).toLocaleDateString()} - {new Date(endDate).toLocaleDateString()}</Typography>
          </Grid>
          <Grid container xs={8} item justifyContent='flex-end'>
            <input type='date' onChange={(e) => setStartDate(e.target.value)} value={startDate} />
            <input type='date' onChange={(e) => setEndDate(e.target.value)} value={endDate} />
          </Grid>
        </Grid>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="subtitle1">Nuevos Miembros: {cashbackStats?.firstTimeCashbackUsers}</Typography>
          <Typography variant="subtitle1">Compras Realizadas: {cashbackStats?.approvedCount}</Typography>
          <Typography variant="subtitle1">Ventas Generadas: {cashbackStats?.totalAmountPaid}</Typography>
        </Paper>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Clientes</Typography>
        <TextField
          label="Buscar Cliente por Nombre"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Cashback Total Asignado</TableCell>
                <TableCell>Cashback Actual</TableCell>
                <TableCell>Número de Compras</TableCell>
                <TableCell>Número de Redenciones</TableCell>
                <TableCell>Total Gastado</TableCell>
                <TableCell>Whatsapp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client, index) => (
                <TableRow key={index}>
                  <TableCell><Link to={`/business/customer/${client._id}`}>{client.waProfileName} {client.lastName}</Link></TableCell>
                  <TableCell>${client.totalCashback}</TableCell>
                  <TableCell>${client.currentCashback}</TableCell>
                  <TableCell>{client.cashbackAssignedCount}</TableCell>
                  <TableCell>{client.cashbackRedeemedCount}</TableCell>
                  <TableCell>${client.totalSpent}</TableCell>
                  <TableCell><a href={`https://api.whatsapp.com/send/?phone=${client.phoneNumber}`}>Whatsapp</a></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredClients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Dashboard;
