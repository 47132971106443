// src/components/CustomerView.tsx
import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, CircularProgress, Paper } from '@mui/material';
import { CustomerData, Purchase, Item } from '../../interfaces/general.ts';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const CustomerView: React.FC = () => {
  const { user } = useAuth0();
  const { customerId } = useParams();
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // Fetch customer data from the API
    const fetchCustomerData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/business/customer`, { params: { customerId: customerId, adminAuth0Id: user?.sub } });
        const data: CustomerData = await response.data;
        setCustomerData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [customerId]);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!customerData) {
    return <Typography variant="h6">No se encontró Información para el cliente.</Typography>;
  }

  const { purchases, totalAmountSpent, mostPurchasedItem } = customerData;

  const filteredPurchases = purchases.filter(purchase =>
    purchase?.items?.some(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) || purchase.date.includes(searchTerm) || purchase.totalAmount.toString().includes(searchTerm)
  );

  return (
    <Container>
      <Typography variant="h4" marginBottom={4} gutterBottom>Información del Cliente: {customerData.customer.waProfileName}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="subtitle1">Número de Compras</Typography>
            <Typography variant="h6">{purchases.length}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="subtitle1">Total Gastado</Typography>
            <Typography variant="h6">${totalAmountSpent.toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="subtitle1">Artículo Más Comprado</Typography>
            <Typography variant="h6">{mostPurchasedItem.name} ({mostPurchasedItem.count} veces)</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h6">Historial de Compras</Typography>
        <TextField
          label="Buscar"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Monto Total</TableCell>
                <TableCell>Artículos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPurchases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((purchase: Purchase, index: number) => (
                <TableRow key={index}>
                  <TableCell>{new Date(purchase.date).toLocaleDateString()}</TableCell>
                  <TableCell>${purchase.totalAmount.toFixed(2)}</TableCell>
                  <TableCell>
                    {purchase.items?.map((item: Item, idx: number) => (
                      <div key={idx}>{item.name} (Cantidad: {item.quantity})</div>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredPurchases.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Container>
  );
};

export default CustomerView;
