import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useAdminUser from "../hooks/useAdminUser.ts";
import { Button } from "reactstrap";


const Home = () => {
  const { user: userAdmin, loading } = useAdminUser()
  const {
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  if (!loading && userAdmin?.adminInformation != null) {
    window.location.replace('/business/dashboard')
  }else if(isAuthenticated){
    window.location.replace('/qr-landing')
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ textAlign: 'center' }}>Fideliza tus clientes</h1>
      <p style={{ textAlign: 'center' }}>Inicia sesión y comienza a fidelizar a tus clientes!</p>
      <Button
        id="qsLoginBtn"
        color="primary"
        className="btn-margin"
        onClick={() => window.location.replace('/business/register')}
      >
        Empieza
      </Button>
    </div>
  )
};

export default Home;
