import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Define the types for the response data
interface CashbackStats {
  totalCashbackAssigned: number;
  totalCashbackRedeemed: number;
  totalAmountPaid: number;
  approvedCount: number;
  rejectedCount: number;
  pendingCount: number;
  firstTimeCashbackUsers: number;
}

interface NewUsersStats {
  firstTimeCashbackUsersCount: number;
}

interface StatsResponse {
  allTimeCashbackStats: CashbackStats;
  cashbackStats: CashbackStats;
  newUsersStats: NewUsersStats;
}

// Define a type for the query parameters
interface StatsQueryParams {
  adminAuth0Id?: string;
  startDate: string;
  endDate: string;
}

// Create the custom hook
const useBusinessStats = ({ adminAuth0Id, startDate, endDate }: StatsQueryParams) => {
  return useQuery<StatsResponse, Error>({
    queryKey: ['businessStats'],
    queryFn: () => axios.get(`${process.env.REACT_APP_API_URL}/business/stats/cashback`, { params: { adminAuth0Id, startDate, endDate } }).then(res => res.data)
  })
};

export default useBusinessStats;
