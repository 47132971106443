import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Define the types for the response data
interface ClientStats {
  _id: string;
  waProfileName: string;
  firstName: string;
  lastName: string;
  totalCashback: number;
  currentCashback: number;
  cashbackAssignedCount: number;
  cashbackRedeemedCount: number;
  totalSpent: number;
  phoneNumber: string;
}

// Define a type for the query parameters
interface ClientStatsQueryParams {
  adminAuth0Id?: string;
}

// Create the custom hook
const useClientStats = ({ adminAuth0Id }: ClientStatsQueryParams) => {
  return useQuery<ClientStats[], Error>({
    queryKey: ['clientStats', adminAuth0Id],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/business/stats/clients`, {
          params: {
            adminAuth0Id
          }
        })
        .then(res => res.data)
  });
};

export default useClientStats;
