import React, { Fragment } from "react";

const QRLanding = () => {
  return (
    <>
      <h1>Gracias por iniciar sesión</h1>
      <p>Te contactaremos en 24 horas o menos</p>
    </>
  )
}
export default QRLanding;
