import { User } from "@auth0/auth0-react"

export const handleRegisterUser = async (user: User) => {
  if (user?.sub) {
    const requestUserDB = await fetch(`${process.env.REACT_APP_API_URL}/user?` + new URLSearchParams({
      auth0Id: user?.sub
    }))
    const dbUser = await requestUserDB.json()
    if (!dbUser.data) {
      const request = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          auth0Id: user.sub,
          firstName: user?.given_name ?? user.email,
          lastName: user?.family_name ?? user.email,
          email: user.email,
        })
      })
      const response = await request.json()
      console.log('Response', response)
      return response
    } else {
      return dbUser
    }
  }
}