import React from "react";
import Logo from '../assets/logo.svg';

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img src={Logo} width={50} height={50}/>
    <p>
      Celer {new Date().getFullYear()}
    </p>
  </footer>
);

export default Footer;
