import React, { useEffect } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import { handleRegisterUser } from "../../services/user.ts";

const BusinessSignup = () => {
  const { isAuthenticated, loginWithPopup, user } = useAuth0();


  useEffect(() => {
    if(isAuthenticated){
      handleRegisterUser(user as User)
    }
  }, [isAuthenticated])

  if (isAuthenticated) {
    return (
      <>
        <h3 style={{ textAlign: 'center' }}>Gracias por registrarte! En unos minutos todo estará listo.</h3>
        <p style={{ textAlign: 'center' }}>Te enviaremos un correo con todo lo que debes saber sobre celer y empezar a vender más.</p>
      </>
    )
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ textAlign: 'center' }}>Empieza a fidelizar a tus clientes!</h1>
      <p style={{ textAlign: 'center' }}>Registrate y empieza a conocer y a fidelizar a tus clientes. Obten estadisticas y comunicate con tus clientes directamente por whatsapp!</p>
      <Button
        id="qsLoginBtn"
        color="primary"
        className="btn-margin"
        onClick={() => loginWithPopup()}
      >
        Registrate
      </Button>
    </div>
  )


}

export default BusinessSignup