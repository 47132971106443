// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAdminUser from '../../hooks/useAdminUser.ts';
import { HandleRedeemInterface } from '../../interfaces/business.interface.ts';
import { Alert, Button, CircularProgress, Container, Divider, Grid, Input, MenuItem, Select, Typography } from '@mui/material';

const BusinessRedeem = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [currentLocation, setCurrentLocation] = useState<string | null>(null)
  const { user: adminUserInfo, loading } = useAdminUser()
  const [code, setCode] = useState('');
  const [response, setResponse] = useState<HandleRedeemInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleRedeem = async () => {
    try {
      setIsLoading(true)
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cashback/business-redeem`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ adminAuth0Id: user?.sub, code, locationId: currentLocation }),
      });

      const data = await response.json();

      if (response.ok) {
        setResponse(data.data);
        setIsLoading(false)
      } else {
        setResponse(data.response.error || 'Error al redimir el código');
        setIsLoading(false)
      }
    } catch (error) {
      setResponse({ error });
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (adminUserInfo) {
      setCurrentLocation(adminUserInfo.adminInformation.locations[0]._id)
    }
  }, [loading])
  if (adminUserInfo && adminUserInfo.adminInformation.locations) {
    return (
      <Container style={{ width: '50%' }}>
        {response ? (
          <Grid item xs={12} marginTop={4} justifyContent={'center'}>
            {response.error ? (
              <Alert severity="error">{response.error}</Alert>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h5">Redención Exitosa!</Typography>
                <p>Cliente: {response.user?.waProfileName}</p>
                <Button
                  style={{ width: '50%' }}
                  variant="contained"
                  color="primary"
                  onClick={() => setResponse(undefined)}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Redencion para otro Cliente'}
                </Button>
              </div>
            )}
          </Grid>
        ) : (
          <>
            <Typography variant="h4" gutterBottom marginBottom={4}>Redimir</Typography>
            <Grid item xs={12} sm={12} md={12}>
              <Select
                fullWidth
                value={currentLocation || ''}
                onChange={(event) => setCurrentLocation(event.target.value)}
                displayEmpty
              >
                {adminUserInfo.adminInformation.locations.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>{name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} marginTop={3} marginBlock={3}>
              <Input
                fullWidth
                type="text"
                placeholder="Ingrese el código"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} sm={12} md={12}>
              <Button
                style={{ width: '50%' }}
                variant="contained"
                color="primary"
                onClick={handleRedeem}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Redimir'}
              </Button>
            </Grid>
          </>
        )}
      </Container >

    )
  }
};

export default BusinessRedeem;
