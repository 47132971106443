import React from "react";
import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react";

const Register = () => {
  const { isAuthenticated, loginWithRedirect, user, isLoading } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }else{
      window.location.replace('/business/dashboard')
    }
  }, [])
}

export default Register