import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import GenerateCode from "./views/GenerateCode";
import QRLanding from "./views/QRLanding.tsx";
import BusinessRedeem from "./views/admin/RedeemWithCode.tsx";
import Register from "./views/user/Register.tsx";
import Dashboard from "./views/admin/Dashboard.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BusinessSignup from "./views/admin/SignupLanding.tsx";
import CustomerView from "./views/admin/Customer.tsx";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  const queryClient = new QueryClient()

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/generate-code" exact component={GenerateCode} />
              <Route path="/qr-landing" exact component={QRLanding} />
              <Route path="/profile" component={Profile} />
              <Route path="/external-api" component={ExternalApi} />
              <Route path="/business/redeem" component={BusinessRedeem} />
              <Route path="/business/dashboard" component={Dashboard} />
              <Route path="/business/customer/:customerId" component={CustomerView} />
              <Route path="/business/register" component={BusinessSignup} />
              <Route path="/user/register" component={Register} />
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
